<template>
  <div class="modal-content">
    <h4>Match Required Fields</h4>
    <div class="row">
      <div class="col-6">
        <label class="form-label">Field Name</label>
      </div>
      <div class="col-6">
        <label class="form-label">Matching Field Name</label>
      </div>
    </div>
    <div
      class="row"
      v-for="field in Object.keys(matched_required_headers)"
      :key="field"
    >
      <div class="col-6">
        <div class="mb-3">
          <input
            id="name"
            type="text"
            :class="['form-control', 'disabled']"
            autocomplete="off"
            :value="field"
            disabled
          />

          <div class="invalid-feedback" v-if="false">
            <code></code> is required.
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <select
            id="name"
            type="text"
            :class="[
              'form-select',
              $v.matched_required_headers[field].$error ? 'is-invalid' : '',
            ]"
            autocomplete="off"
            v-model="$v.matched_required_headers[field].$model"
          >
            <option value="" disabled="">Select matching header</option>
            <option
              v-for="choiceField in parsed_headers"
              :value="choiceField.id"
              :key="choiceField.id"
            >
              {{ choiceField.header }}
            </option>
          </select>
          <div
            class="invalid-feedback"
            v-if="!$v.matched_required_headers[field].required"
          >
            Member <code>{{ field }}</code> is required.
          </div>
          <div
            class="invalid-feedback"
            v-if="!$v.matched_required_headers[field].isUnique"
          >
            <code>{{ field }}</code> is matched to multiple header values, but
            can only match exactly one.
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="unmatchedFields.length > 0 && !$v.matched_required_headers.$invalid"
    >
      <h4>Match Additional Fields</h4>
      <div class="row">
        <div class="col-6">
          <label class="form-label">Field Name</label>
        </div>
        <div class="col-6">
          <label class="form-label">Matching Field Name</label>
        </div>
      </div>
      <div
        class="row"
        v-for="(v, index) in $v.additional_fields.$each.$iter"
        :key="index"
      >
        <div class="col-6">
          <div class="mb-3">
            <input
              id="name"
              type="text"
              v-model="v.field_name.$model"
              :class="['form-control', v.field_name.$error ? 'is-invalid' : '']"
              autocomplete="off"
            />

            <div class="invalid-feedback" v-if="!v.field_name.required">
              Field name is required.
            </div>
            <div class="invalid-feedback" v-if="!v.field_name.isUnique">
              Field name must be unique.
            </div>
            <div class="invalid-feedback" v-if="!v.field_name.notReserved">
              Field can not be a required field name.
            </div>
            <div class="invalid-feedback" v-if="!v.field_name.slug">
              Field name can only have the characters <code>a-z</code>,
              <code>0-9</code>, and <code>_</code>.
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <input
              id="name"
              type="text"
              :class="['form-control', 'disabled']"
              autocomplete="off"
              :value="unmatchedFields[index].header"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";

const uniquelyContained = (value, vm) => {
  return Object.values(vm).filter((v) => v === value).length <= 1;
};

function uniqueName(value) {
  return (
    this.additional_fields.filter((f) => f.field_name == value).length <= 1
  );
}

function notReserved(value) {
  return !Object.keys(this.matched_required_headers).includes(value);
}

const slug = helpers.regex("slug", /^[a-z0-9_]+$/);

export default {
  name: "reputation.cohort.import-csv.match-headers",
  props: {
    value: Object,
  },
  data: () => {
    return {
      parsed_headers: [],
      matched_required_headers: {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
      },
      additional_fields: [],
    };
  },
  computed: {
    unmatchedFields() {
      return this.parsed_headers.filter((v) => {
        return !Object.values(this.matched_required_headers).includes(v.id);
      });
    },
    output() {
      let o = {};
      Object.keys(this.matched_required_headers).forEach((k) => {
        if (this.parsed_headers[this.matched_required_headers[k]]) {
          o[k] = this.parsed_headers[this.matched_required_headers[k]].id;
        }
      });
      if (this.additional_fields.length == this.unmatchedFields.length) {
        for (let i = 0; i < this.additional_fields.length; i++) {
          o[this.additional_fields[i].field_name] = this.unmatchedFields[i].id;
        }
      }
      return o;
    },
    done() {
      return !this.$v.$invalid;
    },
  },
  watch: {
    output: function () {
      if (this.done) {
        this.$set(this.value, "header_map", this.output);
        this.$emit("step-complete", true);
      } else {
        this.$emit("step-complete", false);
      }
    },
  },
  validations: {
    matched_required_headers: {
      first_name: {
        required,
        isUnique: uniquelyContained,
      },
      last_name: {
        required,
        isUnique: uniquelyContained,
      },
      email: {
        required,
        isUnique: uniquelyContained,
      },
      company_name: {
        required,
        isUnique: uniquelyContained,
      },
    },
    additional_fields: {
      $each: {
        field_name: {
          required,
          slug,
          isUnique: uniqueName,
          notReserved: notReserved,
        },
      },
    },
  },
  mounted() {
    this.parsed_headers = this.value.parsed[0].map((v, i) => {
      return { id: i, header: v };
    });
    for (
      let i = 0;
      i <
      this.parsed_headers.length -
        Object.keys(this.matched_required_headers).length;
      i++
    ) {
      this.additional_fields.push({ field_name: "" });
    }
  },
};
</script>

<template>
  <b-modal size="lg" id="import-csv-modal">
    <template #modal-header="{ close }">
      <h5 class="modal-title">Add Members from CSV</h5>
      <button type="button" class="btn-close" @click.prevent="close()"></button>
    </template>

    <component
      :is="steps[step]"
      v-model="upload"
      :cohort="cohort"
      @step-complete="updateStepComplete"
      @save-member="saveMember"
    ></component>

    <template #modal-footer="{ cancel }">
      <a
        href="#"
        @click.prevent="
          reset();
          cancel();
        "
        class="btn btn-link link-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </a>

      <div class="flex-grow-1">
        <div class="steps m-0">
          <a
            href="#"
            :class="['step-item', step === id ? 'active' : '']"
            v-for="(lstep, id) in pSteps"
            :key="lstep"
            @click.prevent="goToStep(id)"
          >
            {{ lstep }}
          </a>
        </div>
      </div>

      <a
        href="#"
        :disabled="!stepComplete"
        @click.prevent="nextStep"
        :class="[
          'btn',
          'btn-primary',
          'ms-auto',
          stepComplete ? '' : 'disabled',
        ]"
        v-if="!upload.saving"
      >
        <span v-if="steps[step] == 'preview'">Create &amp; Upload Members</span>
        <span v-else>Next step</span>
      </a>
      <a
        href="#"
        :class="['btn', 'btn-primary', 'ms-auto', 'disabled']"
        @click.prevent
        v-else
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        Saving
      </a>
    </template>
  </b-modal>
</template>

<script>
import FileUpload from "./ImportCSVSteps/FileUpload.vue";
import MatchHeaders from "./ImportCSVSteps/MatchHeaders.vue";
import Preview from "./ImportCSVSteps/Preview.vue";
import Upload from "./ImportCSVSteps/Upload.vue";
import TrackingService from "@/services/TrackingService";

export default {
  name: "reputation.cohort.import-csv-member-modal",
  components: {
    FileUpload,
    MatchHeaders,
    Preview,
    Upload,
  },
  props: {
    cohort: Object,
  },
  data: () => {
    return {
      stepComplete: false,
      step: 0,
      steps: ["file-upload", "match-headers", "preview", "upload"],
      pSteps: ["Upload", "Headers", "Preview", "Finalize"],
      upload: {
        required_fields: ["first_name", "last_name", "email", "company_name"],
        file: null,
        parsed: null,
        header_map: {},
        saving: false,
      },
    };
  },
  methods: {
    saveMember(member) {
      this.$emit("save-member", member);
    },
    reset() {
      this.step = 0;
      this.upload = {
        required_fields: ["first_name", "last_name", "email", "company_name"],
        file: null,
        parsed: null,
        header_map: {},
        saving: false,
      };
    },
    updateStepComplete(status) {
      this.stepComplete = status;
      if (this.step == this.steps.length - 1) {
        //last step is complete.
        this.$bvModal.hide("import-csv-modal");
        this.reset();
        this.$emit("saved-cohort");
        TrackingService.track("bulk upload complete");
      }
    },
    goToStep(step) {
      if (step < this.step && this.steps[step] != "upload") {
        this.step = step;
        this.stepComplete = true;
      }
    },
    nextStep() {
      this.step += 1;
      this.stepComplete = false;
    },
    async save(ok) {
      ok();
    },
  },
  validations: {},
};
</script>

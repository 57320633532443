<template>
  <b-popover :target="target" triggers="hover">
    <template #title>Review request {{ workflowState }}</template>
    <ul v-if="cohortAction">
      <li v-if="currentStep">
        Current step <code>{{ currentStep }}</code>
      </li>
      <li v-if="currentStep">
        Next step <code>{{ nextStep }}</code>
      </li>
      <li v-if="currentStep">Contact flow last ran {{ lastProcessed }} ago</li>
      <li v-if="currentStep">
        Last changed state {{ timeSinceStateChange }} ago
      </li>
      <li>Contact flow started {{ workflowStarted }} ago</li>
      <li v-if="complete">Contact flow ended {{ workflowEnded }} ago</li>
    </ul>
  </b-popover>
</template>

<script>
import { parseISO, formatDistanceToNowStrict } from "date-fns";
export default {
  name: "reputation.cohort.status-pop",
  props: {
    target: String,
    cohortAction: Object,
  },
  computed: {
    lastProcessed() {
      return formatDistanceToNowStrict(
        parseISO(this.cohortAction.last_processed)
      );
    },
    timeSinceStateChange() {
      return formatDistanceToNowStrict(
        parseISO(this.cohortAction.last_state_change)
      );
    },
    workflowStarted() {
      return formatDistanceToNowStrict(parseISO(this.cohortAction.started));
    },
    workflowEnded() {
      return formatDistanceToNowStrict(parseISO(this.cohortAction.updated));
    },
    currentStep() {
      if (this.cohortAction && this.cohortAction.state) {
        if (this.cohortAction.state == this.cohortAction.target_state) {
          return null;
        } else {
          let step = parseInt(this.cohortAction.state);
          return this.cohortAction.machine.raw_machine.workflow[step].actor;
        }
      }
      return null;
    },
    nextStep() {
      if (this.cohortAction && this.cohortAction.state) {
        if (this.cohortAction.state == this.cohortAction.target_state) {
          return null;
        } else {
          let step = parseInt(this.cohortAction.state);
          if (
            step ==
            this.cohortAction.machine.raw_machine.workflow.length - 1
          ) {
            return "complete";
          }
          return this.cohortAction.machine.raw_machine.workflow[step + 1].actor;
        }
      }
      return null;
    },
    complete() {
      return this.workflowState == "Complete";
    },
    workflowState() {
      if (this.cohortAction) {
        if (this.cohortAction.active) {
          return "In Progress";
        } else {
          if (this.cohortAction.failed) {
            return "Failed";
          } else {
            return "Complete";
          }
        }
      } else {
        return "Not started";
      }
    },
  },
};
</script>

<template>
  <div class="modal-content">
    <h4 class="mb-3">Preview Members</h4>
    <div class="card mb-3" v-for="(member, index) in previewing" :key="index">
      <div class="card-body">
        <div class="row align-items-center mb-3">
          <div class="col-auto">
            <span class="bg-white text-black avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-user"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="7" r="4"></circle>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
              </svg>
            </span>
          </div>
          <div class="col">
            <h3 class="card-title">
              {{ member.required.first_name }} {{ member.required.last_name }}
            </h3>
            <div class="card-subtitle mb-0">
              {{ member.required.company_name }} - {{ member.required.email }}
            </div>
          </div>
        </div>

        <span
          class="badge bg-blue-lt m-1"
          v-for="(additional, idx) in Object.keys(member.additional)"
          :key="`${additional}-${idx}`"
        >
          {{ additional }}:

          <code class="ms-1">
            {{ member.additional[additional] }}
          </code>
        </span>
      </div>
    </div>
    <a
      href="#"
      @click.prevent="showing += 1"
      v-if="showing < preview.length - 1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-plus"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <line x1="12" y1="5" x2="12" y2="19"></line>
        <line x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
      Show More
    </a>
    <span class="text-muted" v-else>Showing all members.</span>
  </div>
</template>

<script>
export default {
  name: "reputation.cohort.import-csv.preview",
  props: {
    value: Object,
  },
  data: () => {
    return {
      preview: [],
      showing: 0,
    };
  },
  computed: {
    previewing() {
      return this.preview.slice(0, this.showing + 1);
    },
  },
  mounted() {
    this.preview = this.value.parsed.slice(1).map((f) => {
      let o = {};
      Object.keys(this.value.header_map).forEach((k) => {
        o[k] = f[this.value.header_map[k]];
      });
      let required = {
        first_name: o.first_name,
        last_name: o.last_name,
        company_name: o.company_name,
        email: o.email,
      };
      delete o.first_name;
      delete o.last_name;
      delete o.company_name;
      delete o.email;
      return { required: required, additional: o };
    });
    this.$emit("step-complete", true);
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","id":"import-csv-modal"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v("Add Members from CSV")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return close()}}})]}},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('a',{staticClass:"btn btn-link link-secondary",attrs:{"href":"#","data-bs-dismiss":"modal"},on:{"click":function($event){$event.preventDefault();_vm.reset();
        cancel();}}},[_vm._v(" Cancel ")]),_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"steps m-0"},_vm._l((_vm.pSteps),function(lstep,id){return _c('a',{key:lstep,class:['step-item', _vm.step === id ? 'active' : ''],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToStep(id)}}},[_vm._v(" "+_vm._s(lstep)+" ")])}),0)]),(!_vm.upload.saving)?_c('a',{class:[
        'btn',
        'btn-primary',
        'ms-auto',
        _vm.stepComplete ? '' : 'disabled' ],attrs:{"href":"#","disabled":!_vm.stepComplete},on:{"click":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}}},[(_vm.steps[_vm.step] == 'preview')?_c('span',[_vm._v("Create & Upload Members")]):_c('span',[_vm._v("Next step")])]):_c('a',{class:['btn', 'btn-primary', 'ms-auto', 'disabled'],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"spinner-border spinner-border-sm me-2",attrs:{"role":"status"}}),_vm._v(" Saving ")])]}}])},[_c(_vm.steps[_vm.step],{tag:"component",attrs:{"cohort":_vm.cohort},on:{"step-complete":_vm.updateStepComplete,"save-member":_vm.saveMember},model:{value:(_vm.upload),callback:function ($$v) {_vm.upload=$$v},expression:"upload"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
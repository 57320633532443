<template>
  <div class="modal-content">
    <div v-if="!parsed">
      <div class="alert alert-danger" role="alert" v-if="error">
        <div class="text-muted">
          {{ errorMessage }}
        </div>
      </div>

      <b-form-file
        accept=".csv"
        class="dropzone mb-3"
        placeholder="Click to choose a file, or drop a file here."
        drop-placeholder="Drop file here..."
        v-model="file"
      ></b-form-file>

      <div class="form-text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-bulb"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"
          ></path>
          <path
            d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"
          ></path>
          <line x1="9.7" y1="17" x2="14.3" y2="17"></line>
        </svg>
        Use any spreadsheet software to export a <code>.csv</code> file. The
        first line of the file should be your field headers. Required fields:
        <code v-for="field in value.required_fields" :key="field">
          {{ field }}
        </code>
      </div>
    </div>
    <div v-else>
      <div class="card col-8">
        <div class="card-header">
          <h3 class="card-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-file-text"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
              <path
                d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
              ></path>
              <line x1="9" y1="9" x2="10" y2="9"></line>
              <line x1="9" y1="13" x2="15" y2="13"></line>
              <line x1="9" y1="17" x2="15" y2="17"></line>
            </svg>
            {{ file.name }}
          </h3>

          <button class="btn btn-outline-danger btn-sm ms-auto" @click="reset">
            Change File
          </button>
        </div>
        <div class="card-body">
          <div class="text-muted">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-list-numbers"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M11 6h9"></path>
              <path d="M11 12h9"></path>
              <path d="M12 18h8"></path>
              <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4"></path>
              <path d="M6 10v-6l-2 2"></path>
            </svg>
            {{ parsed.length }} lines
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropzone {
  border: 2px dashed rgb(175, 175, 175);
  color: rgb(129, 129, 129);
  width: 100%;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import Papa from "papaparse";

export default {
  name: "reputation.cohort.import-csv.file-upload",
  props: {
    value: Object,
  },
  data: () => {
    return {
      file: null,
      parsed: null,
      error: false,
      errorMessage: "",
    };
  },
  mounted() {
    if (this.value.file) {
      this.file = this.value.file;
      this.parsed = this.value.parsed;
    }
  },
  methods: {
    reset() {
      this.file = null;
      this.parsed = null;
      this.value.file = this.file;
      this.error = false;
      this.errorMessage = "";
      this.$emit("step-complete", false);
    },
    fileUploaded(parsed) {
      if (parsed.errors.length > 0) {
        this.file = null;
        this.error = true;
        this.errorMessage =
          "The file you uploaded could not be parsed. Please make sure you are uploading a CSV file.";
      } else {
        if (parsed.data.length == 0) {
          this.file = null;
          this.error = true;
          this.errorMessage = "The file you uploaded is empty.";
          return;
        }

        if (parsed.data[0].length < this.value.required_fields.length) {
          this.file = null;
          this.error = true;
          this.errorMessage = `You must include all required fields: ${this.value.required_fields.join(
            ", "
          )}`;
          return;
        }

        let longerThanFirst = parsed.data
          .map((r, i) => {
            return { d: r, id: i };
          })
          .filter((r) => r.d.length > parsed.data[0].length);
        if (longerThanFirst.length > 0) {
          this.file = null;
          this.error = true;
          this.errorMessage = `The first (header) row must have as many or more entries than any other row in the file. Row ${
            longerThanFirst[0].id + 1
          } has more entries than the header row.`;
          return;
        }

        let headerBlanks = parsed.data[0].filter((v) => v == "");
        if (headerBlanks.length > 0) {
          this.file = null;
          this.error = true;
          this.errorMessage = `The first row (header row) may not have any blank columns.`;
          return;
        }

        this.parsed = parsed.data;
        this.value.file = this.file;
        this.value.parsed = this.parsed;
        this.$emit("step-complete", true);
      }
    },
  },
  watch: {
    file: function () {
      if (this.file) {
        Papa.parse(this.file, {
          skipEmptyLines: true,
          complete: (e) => {
            this.fileUploaded(e);
          },
        });
      }
    },
  },
};
</script>

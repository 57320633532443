<template>
  <div>
    <span
      :id="`status-pop-member-${member.id}`"
      :class="[`workflow-status`, workflowState(member)]"
    ></span>
    <status-pop
      :target="`status-pop-member-${member.id}`"
      :cohortAction="member.cohortaction"
    ></status-pop>
  </div>
</template>

<style lang="scss" scoped>
.workflow-status {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  content: " ";
  &.active {
    background: hsl(50, 84%, 60%);
    box-shadow: 0px 0px 5px 0px hsl(50, 84%, 60%);
  }
  &.failed {
    background: hsl(10, 85%, 60%);
    box-shadow: 0px 0px 5px 0px hsl(10, 85%, 60%);
  }
  &.complete {
    background: hsl(98, 67%, 68%);
    box-shadow: 0px 0px 5px 0px hsl(98, 67%, 68%);
  }
  &.not-started {
    background: hsl(10, 3%, 60%);
    box-shadow: 0px 0px 5px 0px hsl(10, 3%, 60%);
  }
}
</style>

<script>
import StatusPop from "./StatusPop.vue";

export default {
  name: "reputation.cohort.workflow-status",
  components: {
    StatusPop,
  },
  props: {
    member: Object,
  },
  methods: {
    workflowComplete(member) {
      return this.workflowState(member) === "complete";
    },
    workflowState(member) {
      if (member.cohortaction) {
        if (member.cohortaction.active) {
          return "active";
        } else {
          if (member.cohortaction.failed) {
            return "failed";
          } else {
            return "complete";
          }
        }
      } else {
        return "not-started";
      }
    },
  },
};
</script>

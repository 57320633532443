<template>
  <div class="modal-content">
    <h4 class="mb-3">Uploading Members</h4>

    <div class="progress mb-3" v-if="members.length > 0">
      <div
        class="progress-bar"
        :style="`width: ${progress}%`"
        role="progressbar"
      ></div>
    </div>

    <div class="form-text">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-urgent"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M8 16v-4a4 4 0 0 1 8 0v4"></path>
        <path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"></path>
        <rect x="6" y="16" width="12" height="4" rx="1"></rect>
      </svg>
      <strong>
        Please remain on this page while upload completes. Do not close browser
        window or leave this page.
      </strong>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../../../services/ReputationManagementService";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  name: "reputation.cohort.import-csv.upload",
  props: {
    value: Object,
    cohort: Object,
  },
  data: () => {
    return {
      members: [],
      uploaded: 0,
    };
  },
  computed: {
    progress() {
      return Math.floor((this.uploaded / this.members.length) * 100);
    },
  },
  methods: {
    async startUpload() {
      this.value.saving = true;
      let uploading = this.members.map(async (m) => {
        let uploaded = false;
        let success = false;
        let attempts = 1;
        do {
          try {
            if (this.cohort) {
              await ReputationManagementService.addCohortMember(
                this.$auth.activeCompany,
                this.cohort.id,
                m
              );
            } else {
              this.$emit("save-member", m);
            }
            this.uploaded += 1;
            uploaded = true;
            success = true;
          } catch (e) {
            console.log(e);
            await timeout(attempts * 1000);
            attempts += 1;
          }
        } while (!uploaded);
        return success;
      });
      Promise.all(uploading).then(() => {
        this.$emit("step-complete", true);
      });
    },
  },
  mounted() {
    this.members = this.value.parsed.slice(1).map((f) => {
      let o = {};
      Object.keys(this.value.header_map).forEach((k) => {
        o[k] = f[this.value.header_map[k]];
      });
      return {
        data: o,
      };
    });
    this.startUpload();
  },
};
</script>
